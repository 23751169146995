import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`2021 990 Public Disclosure Copy: `}<a parentName="p" {...{
        "href": "https://drive.google.com/file/d/1MQzOyaJ8QIv3xengf4ZsgdSVKT9SU6Jp/view?usp=share_link"
      }}>{`https://drive.google.com/file/d/1MQzOyaJ8QIv3xengf4ZsgdSVKT9SU6Jp/view?usp=share_link`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      